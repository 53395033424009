import { fetchImageSize, fetchUsers } from './api';
import xml2js from 'xml2js';
import React from 'react';

export function extractLeasingPropertyAddress(data) {
  // console.log('[DEBUG] extractLeasingPropertyAddress >> data', data);

  if (!data || !data.address || !data.address[0]) {
    console.log('DEBUG extractLeasingPropertyAddress >> Invalid data structure:', {
      hasData: !!data,
      hasAddress: data && !!data.address,
      hasAddressArray: data && data.address && !!data.address[0]
    });
    return "";
  }

  const addressData = data.address[0];
  let address = "";

  if (addressData.subNumber && addressData.subNumber[0] && (!addressData.$ || addressData.$.display === 'yes')) {
    address += addressData.subNumber[0] + '/';
  }

  if (addressData.streetNumber && addressData.streetNumber[0] && (!addressData.$ || addressData.$.display === 'yes')) {
    address += addressData.streetNumber[0] + ' ';
  }

  if (addressData.street && addressData.street[0] && (!addressData.$ || addressData.$.display === 'yes')) {
    address += addressData.street[0] + ', ';
  }

  if (addressData.suburb && addressData.suburb[0] && addressData.suburb[0]._ && (!addressData.suburb[0].$ || addressData.suburb[0].$.display === 'yes')) {
    address += addressData.suburb[0]._ + ', ';
  }

  if (addressData.state && addressData.state[0] && (!addressData.$ || addressData.$.display === 'yes')) {
    address += addressData.state[0] + ' ';
  }

  if (addressData.postcode && addressData.postcode[0] && (!addressData.$ || addressData.$.display === 'yes')) {
    address += addressData.postcode[0] + ', ';
  }

  if (addressData.country && addressData.country[0] && (!addressData.$ || addressData.$.display === 'yes')) {
    address += addressData.country[0];
  }

  return address;
}

/**
 * Outputs:
 *  {
 *    name: 'H.PM Leasing',
 *    telephones: { 'default': number, 'mobile': number }
 *    email: 'h.pm@hoyee.com.au'
 *  }
 * @param data
 * @returns {{name: string, telephones: *[], email: string}}
 */
export function extractLeasingPropertyContact(data) {
  let contact = {
    name: null,
    telephones: {},
    email: null
  };

  if (!data.listingAgent || !data.listingAgent[0]) {
    return contact;
  }

  const agent = data.listingAgent[0];

  // Extract name
  if (agent.name && agent.name[0]) {
    contact.name = agent.name[0];
  }

  // Extract telephone numbers
  if (agent.telephone) {
    agent.telephone.forEach(tel => {
      if (typeof tel === 'string') {
        contact.telephones.default = tel;
      } else if (tel._ && tel.$ && tel.$.type) {
        contact.telephones[tel.$.type] = tel._;
      }
    });
  }

  // Extract email
  if (agent.email && agent.email[0]) {
    contact.email = agent.email[0];
  }

  return contact;
}

export function extractLeasingPropertyCarSpaces(data) {
  if (!data || !data.features || !data.features[0]) {
    return '';
  }

  if (data.features[0].garages && data.features[0].garages[0] !== '') {
    return data.features[0].garages[0];
  }
  if(data.features[0].carports && data.features[0].carports[0] !== '') {
    return data.features[0].carports[0];
  }
  if(data.features[0].carspaces && data.features[0].carspaces[0] !== '') {
    return data.features[0].carspaces[0];
  }

  return '';
}

export function extractLeasingPropertyRent(data) {
  if (!data || !data.rent || !data.rent[0] || data.rent[0]._ === '' || data.rent[0].$.display !== 'yes') {
    return 'Contact Agent';
  }

  let period = data.rent[0].$.period.toLowerCase();
  if(period === 'weekly') {
    period = 'week';
  } else if(period === 'monthly') {
    period = 'month';
  } else if (period === 'annually') {
    period = 'year';
  }

  return `$${data.rent[0]._} / ${period}`;
}

export function extractLeasingPropertyCategory(data) {
  if (!data || !data.category || !data.category[0]) {
    return 'Unknown';
  }

  return data.category[0].$.name;
}

export async function filterLeasingImagesBySize(propertiesLeasing, size = 20) {
  for (let property of propertiesLeasing) {
    // console.log('filterLeasingImagesBySize >> property:', extractLeasingPropertyAddress(property));
    // console.log('filterLeasingImagesBySize >> urls:', property.images[0].img.map(img => img.$.url));
    if (property.images && property.images[0] && property.images[0].img) {
      // Filter images based on size
      const filteredImages = [];
      for (let img of property.images[0].img) {
        if (img.$ && img.$.url) {
          // console.log('url', img.$.url)
          const imageSize = await fetchImageSize(img.$.url);
          // console.log('filterLeasingImagesBySize >> imageSize', imageSize);
          // console.log('filterLeasingImagesBySize >> imageSize type', typeof imageSize.size);
            if (imageSize
              && imageSize.size
              && typeof imageSize.size === 'number'
              && imageSize.size > 0
              && imageSize.size <= size * 1024 * 1024) { // Assuming size is returned in bytes
                filteredImages.push(img);
            }
        }
      }
      property.images[0].img = filteredImages;
      // console.log('filterLeasingImagesBySize >> filtered urls:', property.images[0].img.map(img => img.$.url));
    }
  }

  return propertiesLeasing;
}


export const parseToHTML = (text) => {
  const html = text.split('\n\n').map(paragraph =>
    `<p>${paragraph.replace(/\n/g, '<br>')}</p>`).join('');

  return html;
}

/**
 * Generate proper HTML Element by give text.
 * @param text
 * @returns {Element}
 */
export const createMarkup = (text) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: parseToHTML(text) }}/>
  )
};

export const fetchProperties = async (action, params) => {
  // Initialize properties and fetchedUsers array
  const properties = [];
  const fetchedUsers = [];

  // Fetching users
  try {
    const response = await fetchUsers({ pagesize: 50 });
    fetchedUsers.push(...response.items);
  } catch (error) {
    console.log(error);
  }

  if (fetchedUsers.length === 0) {
    console.log('Do not have users');
    return;
  }

  try {
    let page = 1;

    while (page) {
      const finalParams = { page, ...params };
      const response = await action(finalParams);
      console.log('fetchProperties >> response', response);

      if (!response.type) {
        // Sales API response contains items property
        properties.push(
          ...response.items.filter((item) =>
            item.contactStaff.some((staff) =>
              fetchedUsers.some((user) => user.id === staff.id)
            )
          )
        );
        page = response.urls.next ? page + 1 : null;
      } else {
        console.log('fetchProperties >> page', page, ' items', response.items);

        page = response.urls.next ? page + 1 : null;

        if (response.items) {
          const data = response.items;
          // console.log('[DEBUG] fetchProperties >> parsing XML data', data);
          const parser = new xml2js.Parser();
          for (let xmlStr of data) {
            parser.parseString(xmlStr, (err, result) => {
              if (err) {
                console.error("Error parsing XML:", err);
              } else {
                // console.log('fetchProperties >> XML parse result:', JSON.stringify(result, null, 2));
                
                try {
                  if (!result.root || !result.root.document) {
                    console.log('fetchProperties >> Missing root or document in XML result');
                    return;
                  }

                  result.root.document.forEach(doc => {
                    try {
                      // console.log('fetchProperties >> Processing document:', JSON.stringify(doc, null, 2));

                      let property;
                      if(doc.rental && doc.rental[0]) {
                        property = doc.rental[0];
                      } else if(doc.commercial && doc.commercial[0]) {
                        property = doc.commercial[0];
                      } else {
                        console.log('[DEBUG]fetchProperties >> Missing rental or commercial data in document', JSON.stringify(doc, null, 2));
                        return;
                      }

                      const address = extractLeasingPropertyAddress(property);
                      const find = properties.find(p => extractLeasingPropertyAddress(p) === address);
                      !find && properties.push(property);
                    } catch (docError) {
                      console.error('Error processing document:', docError);
                      console.log('DEBUG extractLeasingPropertyAddress >> input data:', JSON.stringify(data, null, 2));
                    }
                  });
                } catch (rootError) {
                  console.error('Error processing XML root:', rootError);
                  console.log('DEBUG extractLeasingPropertyAddress >> input data:', JSON.stringify(data, null, 2));
                }
              }
            });
          }
        }
      }

      console.log('fetchProperties >> page', page, properties);
    }

    return properties;
  } catch (error) {
    console.error('fetchProperties error:', error);
    throw error;
  }
};
