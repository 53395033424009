import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  fetchPropertiesLease,
  fetchPropertiesSale,
  fetchResidentialPropertiesSold,
  fetchUsers,
  imageProxyUrl
} from '../../api';
import * as PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import Slider from "react-slick";
import 'react-loading-skeleton/dist/skeleton.css';
import xml2js from 'xml2js';
import { array } from 'prop-types';
import useStore from '../../store';
import { toJS } from 'mobx';
import {
  extractLeasingPropertyAddress,
  extractLeasingPropertyCarSpaces,
  extractLeasingPropertyContact, extractLeasingPropertyRent,
  fetchProperties, filterLeasingImagesBySize
} from '../../utility';

const ProductSliderV1 = () => {
  let publicUrl = process.env.PUBLIC_URL + '/'
  const [propertiesSell, setPropertiesSell] = useState([]);
  const [propertiesSold, setPropertiesSold] = useState([]);
  const [propertiesLease, setPropertiesLease] = useState([]);
  const [featuredPropertiesSell, setFeaturedPropertiesSell] = useState([null, null, null, null, null, null, null, null, null, null]);
  const [featuredPropertiesLease, setFeaturedPropertiesLease] = useState([null, null, null, null, null, null, null, null, null, null]);

  const sliderRef = useRef(null);

  const [users, getUsers] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const { globalStore } = useStore();

  function Arrow(props) {
    const { className, style, onClick, iconClass } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{ ...style }}
      >
        <i className={`fas ${iconClass}`} alt="Arrow Icon"></i>
      </div>
    );
  }

  const sliderSettings2 = {
    prevArrow: <Arrow iconClass={'fa-chevron-left'}/>,
    nextArrow: <Arrow iconClass={'fa-chevron-right'}/>,
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const exclusiveProperties = [
    'b1a0006d52834c9d88e4d4b874bc3592',
    'b1c30106e6f84609a4e2b51b2167e1e0',
    'b1d20068a468425d9bf494b79c0ad519',
    'b1ee014ed1ed4055b85264898e62b816',
    'b22d00f92b8646fba9048439e8321603',
    'b1e801162a364d98b1e30be03ee692bf',
  ];

  useEffect(() => {
    async function fetchData() {
      // Initiate both fetchPropertiesSale and fetchPropertiesLease but don't await them yet
      const propertiesSellPromise = fetchProperties(fetchPropertiesSale, {
        pagesize: 200,
        sort: 'modified',
        published: true,
        status: 'listing'
      });

      // const propertiesSoldPromise = fetchProperties(fetchResidentialPropertiesSold, {
      //   pagesize: 200,
      //   sort: 'modified',
      // });

      // TODO: 1. Check global store before fetch data. 2. Implement no property found message.
      const propertiesLeasingPromise = fetchProperties(fetchPropertiesLease);

      propertiesSellPromise
        .then(propertiesSell => {
          propertiesSell.reverse();
          setPropertiesSell(propertiesSell);
          globalStore.allSellProperties.length === 0 && globalStore.setSellProperties(propertiesSell);

          const containsPhotos = propertiesSell && propertiesSell.filter(property => property.photos.length > 0);
          const featuredSale = containsPhotos && containsPhotos.slice(0, 10) || [];
          setFeaturedPropertiesSell([...featuredSale]);

          console.log('sell', propertiesSell);
          // console.log('sell featuredPropertiesSell', featuredSale);
        })
        .catch(error => {
          console.log('Error fetching propertiesSell', error);
        });

      // propertiesSoldPromise
      //   .then(propertiesSold => {
      //     setPropertiesSold(propertiesSold);
      //     globalStore.allSoldProperties.length === 0 && globalStore.setSoldProperties(propertiesSold);
      //
      //     console.log('sold', propertiesSold);
      //   })
      //   .catch(error => {
      //     console.log('DEBUG >> sold >> Error fetching propertiesSold', error);
      //   });

      propertiesLeasingPromise
        .then(async propertiesLeasing => {
          console.log('product-slider-v1 propertiesLeasing pre-filter', propertiesLeasing.length);

          propertiesLeasing = propertiesLeasing.filter(property => 
            property.uniqueID[0] && !exclusiveProperties.includes(property.uniqueID[0])
          );

          console.log('product-slider-v1 propertiesLeasing post-filter', propertiesLeasing.length);

          propertiesLeasing.sort((a, b) => {
            if (!a.dateAvailable || !b.dateAvailable) {
              return 0;
            }
            const dateA = new Date(a.dateAvailable[0]);
            const dateB = new Date(b.dateAvailable[0]);
            return dateB - dateA; // This sorts from latest to earliest
          });

          propertiesLeasing = await filterLeasingImagesBySize(propertiesLeasing);

          setPropertiesLease(propertiesLeasing);
          globalStore.allLeasingProperties.length === 0 && globalStore.setLeasingProperties(propertiesLeasing);

          const featuredLeasing = propertiesLeasing && propertiesLeasing.length > 10 && propertiesLeasing.slice(0, 10) || propertiesLeasing;
          if (featuredLeasing.length < 3) {
            const diff = 3 - featuredLeasing.length;
            for (let i = 0; i < diff; i++) {
              featuredLeasing.push(featuredLeasing[i]);
            }
          }
          setFeaturedPropertiesLease([...featuredLeasing]);

          console.log('lease', propertiesLeasing);
          // console.log('lease featuredPropertiesLease', featuredLeasing);
        })
        .catch(error => {
          console.log('Error fetching propertiesLease', error);
        })
    }

    fetchData();

  }, []);

  function PropertyItemSale(props) {
    const { property } = props;
    const contact = property && property.contactStaff && property.contactStaff[0] ? {
      name: property.contactStaff[0].firstName + ' ' + property.contactStaff[0].lastName,
      email: property.contactStaff[0].email,
      photo: property.contactStaff[0].photo ? property.contactStaff[0].photo.thumb_360 : null,
      telephone: property.contactStaff[0].phoneNumbers && property.contactStaff[0].phoneNumbers.find(p => p.typeCode === 'D')?.number || '',
      mobile: property.contactStaff[0].phoneNumbers && property.contactStaff[0].phoneNumbers.find(p => p.typeCode === 'M')?.number || '',
    } : {};

    if (!property) {
      return (
        <div className="ltn__product-item ltn__product-item-4 text-center---">
          <div className="product-img go-top">
            <Skeleton height={280} width={'100%'}/>
          </div>
          <div className="product-info">
            <Skeleton height={20} width={'60%'}/>
            <Skeleton height={20} width={'80%'} count={3}/>
          </div>
          <div className="product-info-bottom">
            <Skeleton height={40} width={40} circle={true}/>
            <Skeleton height={20} width={'60%'}/>
          </div>
        </div>
      )
    } else {
      return (
        <div className="ltn__product-item ltn__product-item-4 text-center---">
          <div className="product-img go-top">
            <Link
              to={{
                pathname: "/property-details",
                search: `?id=${property.id}&lifeid=${property.saleLifeId}&type=sale`,
                state: {
                  property: property,
                }
              }}
            >
              <img
                src={property.photos[0].thumbnails.thumb_1024}
                alt="#"
                style={{
                  height: '280px',
                  width: '100%',  // This sets the width to fill the container
                  objectFit: 'cover'  // This scales the image to cover the space
              }}
              />
            </Link>
            <div className="product-badge">
              <ul>
                <li className="sale-badge bg-red">For Sale</li>
              </ul>
            </div>
            <div className="product-img-location-gallery">
              <div className="product-img-location">
                <ul>
                  <li>
                    <Link
                      to={{
                        pathname: "/property-details",
                        search: `?id=${property.id}&lifeid=${property.saleLifeId}&type=sale`,
                        state: {
                          property: property,
                        }
                      }}
                    >
                      {property.displayAddress}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="product-img-gallery go-top">
                <ul>
                  <li>
                    <Link
                      to={{
                        pathname: "/property-details",
                        search: `?id=${property.id}&lifeid=${property.saleLifeId}&type=sale`,
                        state: {
                          property: property,
                        }
                      }}
                    >
                      <i className="fas fa-camera"/>
                    </Link>
                  </li>
                  {/*<li>*/}
                  {/*  <Link to="/property-details"><i className="fas fa-film" /> 2</Link>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </div>
          </div>
          <div className="product-info">
            <div className="product-price">
              <span>{property.displayPrice ? property.displayPrice : 'Contact Agent'}</span>
            </div>
            <h2 className="product-title go-top" style={{ height: '44px' }}>
              <Link
                to={{
                  pathname: "/property-details",
                  search: `?id=${property.id}&lifeid=${property.saleLifeId}&type=sale`,
                  state: {
                    property: property,
                  }
                }}
              >
                {property.displayAddress}
              </Link>
            </h2>
            {/*<div className="product-description">*/}
            {/*    <p>{property.windowCardDescription}</p>*/}
            {/*</div>*/}
            <ul className="ltn__list-item-2 ltn__list-item-2-before">
              <li><span>{property.bed} <i className="flaticon-bed" style={{verticalAlign: 'middle'}}/></span>
                Bedrooms
              </li>
              <li><span>{property.bath} <i className="flaticon-clean" style={{verticalAlign: 'middle'}}/></span>
                Bathrooms
              </li>
              <li><span>{property.carSpaces || property.carports || property.garages || property.openSpaces} <i className="flaticon-car" style={{verticalAlign: 'middle'}}/></span>
                Carpark
              </li>
              {/*<li><span>{property.floorArea && property.floorArea.value > 0.0 ? property.floorArea.value : '-'}<i*/}
              {/*  className="flaticon-square-shape-design-interface-tool-symbol"/></span>*/}
              {/*  Square meters*/}
              {/*</li>*/}
            </ul>
          </div>
          <div className="product-info-bottom">
            <div className="real-estate-agent">
              <div className="agent-img go-top">
                {/*<Link to="/team-details">*/}
                  <img
                  // src={'https://i1.au.reastatic.net/240x240,gravity=north,quality=90/d9f9755a7de4dfc9b84630a1ef35b9b74d1a840286cc581176af86fae0a0f1b6/main.jpg'}
                  src={contact.photo}
                  alt="#"
                  />
                {/*</Link>*/}
              </div>
              <div className="agent-brief go-top">
                <h5 style={{ marginBottom: '5px' }}>{contact.name}</h5>

                <p style={{marginBottom: '5px'}}>
                  {/*<i className="fab icon-mail" style={{paddingRight: '1em'}}/>*/}
                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                </p>
                <p style={{marginBottom: '5px'}}>
                  {/*<i className="fab icon-phone-call" style={{paddingRight: '1em'}}/>*/}
                  <a href={`tel:${contact.mobile || contact.photo}`}>{contact.mobile || contact.photo}</a>
                </p>

                {/*{*/}
                {/*  contact.email &&*/}
                {/*  <p><a href={`mailto:${contact.email}`}>{contact.email}</a></p>*/}
                {/*}*/}
                {/*<p>{contact.mobile ? <a href={`tel:${contact.mobile}`}>{contact.mobile}</a> : contact.telephone}</p>*/}
              </div>
            </div>
            {/*<div className="product-hover-action">*/}
            {/*    <ul>*/}
            {/*        <li>*/}
            {/*            <a href="#" title="Quick View" data-bs-toggle="modal"*/}
            {/*               data-bs-target="#quick_view_modal">*/}
            {/*                <i className="flaticon-expand"/>*/}
            {/*            </a>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <a href="#" title="Wishlist" data-bs-toggle="modal"*/}
            {/*               data-bs-target="#liton_wishlist_modal">*/}
            {/*                <i className="flaticon-heart-1"/></a>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*			<span className="go-top">*/}
            {/*			<Link to="/property-details" title="Product Details">*/}
            {/*				<i className="flaticon-add"/>*/}
            {/*			</Link>*/}
            {/*			</span>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
          </div>
        </div>
      )
    }
  }

  PropertyItemSale.propTypes = { property: PropTypes.any };

  function PropertyItemLease(props) {
    const { property } = props;

    if (!property) {
      return (
        <div className="ltn__product-item ltn__product-item-4 text-center---">
          <div className="product-img go-top">
            <Skeleton height={280} width={'100%'} />
          </div>
          <div className="product-info">
            <Skeleton height={20} width={'60%'} />
            <Skeleton height={20} width={'80%'} count={3} />
          </div>
          <div className="product-info-bottom">
            <Skeleton height={40} width={40} circle={true} />
            <Skeleton height={20} width={'60%'} />
          </div>
        </div>
      )
    } else {
      const contactData = extractLeasingPropertyContact(property);
      const contact = {
        name: contactData.name || 'Chloe Zang',
        telephone: contactData.telephones.default,
        mobile: contactData.telephones.mobile || '',
        email: contactData.email,
        photo: property.images[0].img[0] ? property.images[0].img[0]['$'].url : null,
      };
      return (
        <div className="ltn__product-item ltn__product-item-4 text-center---">
          <div className="product-img go-top">

            <Link
              to={{
                pathname: "/property-details",
                search: `?id=${property.uniqueID[0]}&type=rent`,  // query parameters can be set here
                state: {
                  property: property,
                }
              }}
            >
              <img
                src={
                  property.images[0].img[0]
                    ? imageProxyUrl(property.images[0].img[0]['$'].url)
                    : null
                }
                alt="#"
                style={{
                  height: '280px',
                  width: '100%',  // This sets the width to fill the container
                  objectFit: 'cover'  // This scales the image to cover the space
              }}
              />
            </Link>
            <div className="product-badge">
              <ul>
                <li className="sale-badge bg-green">For Rent</li>
              </ul>
            </div>
            <div className="product-img-location-gallery">
              <div className="product-img-location">
                <ul>
                  <li>
                    {extractLeasingPropertyAddress(property)}
                  </li>
                </ul>
              </div>
              <div className="product-img-gallery go-top">
                <ul>
                  <li>
                    <Link
                      to={{
                        pathname: "/property-details",
                        search: `?id=${property.uniqueID[0]}&type=rent`,  // query parameters can be set here
                        state: {
                          property: property,
                        }
                      }}
                    >
                      <i className="fas fa-camera"/> 4
                    </Link>
                  </li>
                  {/*<li>*/}
                  {/*  <Link to="/property-details"><i className="fas fa-film" /> 2</Link>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </div>
          </div>
          <div className="product-info">
            <div className="product-price">
              <span>{extractLeasingPropertyRent(property)}</span>
            </div>
            <h2 className="product-title go-top" style={{height: '44px'}}>
              <Link
                to={{
                  pathname: "/property-details",
                  search: `?id=${property.uniqueID[0]}&type=rent`,  // query parameters can be set here
                  state: {
                    property: property,
                  }
                }}
              >
                {extractLeasingPropertyAddress(property)}
              </Link>
            </h2>
            {/*<div className="product-description">*/}
            {/*    <p>{property.windowCardDescription}</p>*/}
            {/*</div>*/}
            <ul className="ltn__list-item-2 ltn__list-item-2-before">
              <li><span>{property.features[0].bedrooms[0]} <i className="flaticon-bed" style={{verticalAlign: 'middle'}}/></span>
                Bedrooms
              </li>
              <li><span>{property.features[0].bathrooms[0]} <i className="flaticon-clean" style={{verticalAlign: 'middle'}}/></span>
                Bathrooms
              </li>
              <li><span>{extractLeasingPropertyCarSpaces(property)} <i className="flaticon-car" style={{verticalAlign: 'middle'}}/></span>
                Carpark
              </li>
              {/*<li><span>{property.floorArea && property.floorArea.value > 0.0 ? property.floorArea.value : '-'}<i*/}
              {/*  className="flaticon-square-shape-design-interface-tool-symbol"/></span>*/}
              {/*  Square meters*/}
              {/*</li>*/}
            </ul>
          </div>
          <div className="product-info-bottom">
            <div className="real-estate-agent">
              <div className="agent-hoyee-img go-top" style={{maxWidth: '64px', paddingTop: '4px'}}>
                <img
                  src={'/assets/img/H.PM.jpg'}
                  alt="#"
                />
              </div>
              <div className="agent-brief go-top">
                <h5 style={{ marginBottom: '5px' }}><Link to="/team-details">{contact.name}</Link></h5>
                <p style={{ marginBottom: '0px' }}>{contact.email}</p>
                <p>{contact.mobile ? <a href={`tel:${contact.mobile}`}>{contact.mobile}</a> : contact.photo}</p>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  PropertyItemLease.propTypes = { property: PropTypes.any };

  function Modals() {
    return (
      <>
        <div className="ltn__modal-area ltn__add-to-cart-modal-area">
          <div className="modal fade" id="liton_wishlist_modal" tabIndex={-1}>
            <div className="modal-dialog modal-md" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="ltn__quick-view-modal-inner">
                    <div className="modal-product-item">
                      <div className="row">
                        <div className="col-12">
                          <div className="modal-product-img">
                            <img src={publicUrl + 'assets/img/product/7.png'} alt="#"/>
                          </div>
                          <div className="modal-product-info go-top">
                            <h5><Link to="/property-details">Brake Conversion Kit</Link></h5>
                            <p className="added-cart"><i
                              className="fa fa-check-circle"/> Successfully added to your
                              Wishlist</p>
                            <div className="btn-wrapper">
                              <Link to="/wishlist"
                                    className="theme-btn-1 btn btn-effect-1">View
                                Wishlist</Link>
                            </div>
                          </div>
                          {/* additional-info */}
                          <div className="additional-info d-none">
                            <p>We want to give you <b>10% discount</b> for your first
                              order, <br/> Use discount code at
                              checkout</p>
                            <div className="payment-method">
                              <img src={publicUrl + 'assets/img/icons/payment.png'}
                                   alt="#"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__modal-area ltn__quick-view-modal-area">
          <div className="modal fade" id="quick_view_modal" tabIndex={-1}>
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    {/* <i class="fas fa-times"></i> */}
                  </button>
                </div>
                <div className="modal-body">
                  <div className="ltn__quick-view-modal-inner">
                    <div className="modal-product-item">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <div className="modal-product-img">
                            <img src={publicUrl + 'assets/img/product/4.png'} alt="#"/>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="modal-product-info">
                            <div className="product-ratting">
                              <ul>
                                <li><a href="#"><i className="fas fa-star"/></a></li>
                                <li><a href="#"><i className="fas fa-star"/></a></li>
                                <li><a href="#"><i className="fas fa-star"/></a></li>
                                <li><a href="#"><i className="fas fa-star"/></a></li>
                                <li><a href="#"><i className="fas fa-star"/></a></li>
                                <li className="review-total"><a href="#"> ( 95 Reviews
                                  )</a>
                                </li>
                              </ul>
                            </div>
                            <h3>Brake Conversion Kit</h3>
                            <div className="product-price">
                              <span>$149.00</span>
                              <del>$165.00</del>
                            </div>
                            <div className="modal-product-meta ltn__product-details-menu-1">
                              <ul>
                                <li>
                                  <strong>Categories:</strong>
                                  <span className="go-top">
                                                                           <Link to="/blog">Parts</Link>
                                                                           <Link to="/blog">Car</Link>
                                                                           <Link to="/blog">Seat</Link>
                                                                           <Link to="/blog">Cover</Link>
                                                                           </span>
                                </li>
                              </ul>
                            </div>
                            <div className="ltn__product-details-menu-2">
                              <ul>
                                <li>
                                  <div className="cart-plus-minus">
                                    <input type="text" defaultValue="02"
                                           name="qtybutton"
                                           className="cart-plus-minus-box"/>
                                  </div>
                                </li>
                                <li>
                                  <a href="#" className="theme-btn-1 btn btn-effect-1"
                                     title="Add to Cart"
                                     data-bs-toggle="modal"
                                     data-bs-target="#add_to_cart_modal">
                                    <i className="fas fa-shopping-cart"/>
                                    <span>ADD TO CART</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <hr/>
                            <div className="ltn__social-media">
                              <ul>
                                <li>Share:</li>
                                <li><a href="#" title="Facebook"><i
                                  className="fab fa-facebook-f"/></a></li>
                                <li><a href="#" title="Twitter"><i
                                  className="fab fa-twitter"/></a></li>
                                <li><a href="#" title="Linkedin"><i
                                  className="fab fa-linkedin"/></a></li>
                                <li><a href="#" title="Instagram"><i
                                  className="fab fa-instagram"/></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__modal-area ltn__add-to-cart-modal-area">
          <div className="modal fade" id="add_to_cart_modal" tabIndex={-1}>
            <div className="modal-dialog modal-md" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="ltn__quick-view-modal-inner">
                    <div className="modal-product-item">
                      <div className="row">
                        <div className="col-12">
                          <div className="modal-product-img">
                            <img src={publicUrl + 'assets/img/product/1.png'} alt="#"/>
                          </div>
                          <div className="modal-product-info go-top">
                            <h5 className="go-top"><Link to="/property-details">Brake
                              Conversion
                              Kit</Link></h5>
                            <p className="added-cart"><i className="fa fa-check-circle"/>Successfully
                              added to your Cart
                            </p>
                            <div className="btn-wrapper">
                              <Link to="/cart" className="theme-btn-1 btn btn-effect-1">View
                                Cart</Link>
                              <Link to="/checkout"
                                    className="theme-btn-2 btn btn-effect-2">Checkout</Link>
                            </div>
                          </div>
                          {/* additional-info */}
                          <div className="additional-info d-none">
                            <p>We want to give you <b>10% discount</b> for your first
                              order, <br/> Use discount code at
                              checkout</p>
                            <div className="payment-method">
                              <img src={publicUrl + 'assets/img/icons/payment.png'}
                                   alt="#"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }


  return (
    <div>
      <div className="ltn__product-slider-area ltn__product-gutter pt-25 pb-15 plr--7">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__section-title-2 text-center">
                {/*<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Properties</h6>*/}
                <h1 className="section-title">Featured Listings</h1>
              </div>
              <div
                className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center"
                style={{ marginBottom: '14px' }}
              >
                <div className="nav">
                  <a className="active show" data-bs-toggle="tab" href="#sell_tab">Sell</a>
                  <a data-bs-toggle="tab" href="#rent_tab">Rent</a>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade active show" id="sell_tab">
              <div className="row slick-arrow-1" ref={sliderRef}>
                <Slider {...sliderSettings2}>
                {
                    featuredPropertiesSell.length > 0 && featuredPropertiesSell.map((property, index) => {
                        return (
                          <div className="col-lg-12" key={`featured-sell-${index}`}>
                            <PropertyItemSale property={property}/>
                          </div>
                        )})
                }
                </Slider>
              </div>
            </div>
            <div className="tab-pane fade" id="rent_tab">
              <div className="row ltn__product-slider-item-four-active-full-width slick-arrow-1" ref={sliderRef}>
                <Slider {...sliderSettings2}>
                  {
                    featuredPropertiesLease.length > 0 && featuredPropertiesLease.map((property, index) => {
                      return (
                        <div className="col-lg-12">
                          <PropertyItemLease property={property} key={`featured-lease-${index}`}/>
                        </div>
                      )})
                  }
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<Modals/>*/}
    </div>
  )
}

export default ProductSliderV1
