import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Property } from '../../types/type';
import useStore from '../../store';
import {
  extractLeasingPropertyAddress,
  extractLeasingPropertyCarSpaces,
  extractLeasingPropertyContact
} from '../../utility';
import { imageProxyUrl, sendEmail, fetchPropertiesLease } from '../../api';
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import AlertMessage from '../alert-message';
import './shop-details-lease.css'
import { fetchProperties, filterLeasingImagesBySize } from '../../utility';

/**
 * @typedef {Object} ShopDetailsProps
 * @property {Property} property
 * @property {string} paddingTop
 * @property {string} type
 */

/**
 * @param {ShopDetailsProps} props
 */
const ShopDetailsLease = (props) => {
  // https://www.hoyee.com.au/#/property-details?id=b1d800dfbbcb486ca792687c5a4a2e20&type=rent
  let { property, type } = props;
  property = property || undefined;
  let publicUrl = process.env.PUBLIC_URL + '/'

  const getCategory = () => {
    if (type === 'sale') {
      return 'For Sale';
    } else if (type === 'rent') {
      return 'For Rent';
    } else if (type === 'sold') {
      return 'Sold';
    } else {
      return '';
    }
  }

  const getHTML = (text) => {
    const html = text.split('\n\n').map(paragraph =>
      `<p>${paragraph.replace(/\n/g, '<br>')}</p>`).join('');

    return html;
  }

  const getFloorPlans = () => {
    if (property && property.photos && property.photos.length > 0) {
      const plans = property.photos.filter(p => p.type === 'Floorplan');
      console.log('getFloorPlans >> plans', plans);
      return plans;
    }

    return [];
  }
  const floorPlans = getFloorPlans();

  const latitude = property.geolocation && property.geolocation.latitude || undefined;
  const longitude = property.geolocation && property.geolocation.longitude || undefined;

  // Construct the Google Maps URL using the property's latitude and longitude
  // TODO: Disable the google map integration for now.
  const googleMapsSrc = null;

  const createMarkup = (text) => {
    return { __html: getHTML(text) };
  };

  // Extract agent information
  const contactData = extractLeasingPropertyContact(property);
  const agent = {
    name: contactData.name || 'Chloe Zang',
    telephone: contactData.telephones.default,
    mobile: contactData.telephones.mobile || '',
    email: contactData.email,
    photo: property?.images[0].img[0] ? property?.images[0].img[0]['$'].url : null,
  };
  console.log('agent', agent);

  const fullAddress = extractLeasingPropertyAddress(property);
  console.log('fullAddress', fullAddress);

  const agentName = agent ? agent.name : undefined;
  const agentEmail = agent ? agent.email : undefined;
  const agentPhone = agent ? (agent.mobile || agent.telephone) : undefined;
  console.log('agent details', agentName, agentEmail, agentPhone);

  const [imageSizes, setImageSizes] = useState({});

  const loadImageSize = (imgUrl) => {
    const img = new Image();
    img.onload = () => {
      setImageSizes(prevSizes => ({
        ...prevSizes,
        [imgUrl]: { width: img.naturalWidth, height: img.naturalHeight }
      }));
    };
    img.src = imgUrl;
  };

  const noPropertyFound = React.useRef(false);
  const setNoPropertyFound = (value) => {
    noPropertyFound.current = value;
  }
  const { globalStore } = useStore();

  // // Fetch property data if not provided
  // useEffect(() => {
  //   if (!property.id) {
  //     console.log('DEBUG >> useEffect >> property.id', property.id);
  //     const params = new URLSearchParams(window.location.search);
  //     const propertyId = params.get('id');
  //     if (propertyId) {
  //       const propertiesLeasingPromise = fetchProperties(fetchPropertiesLease);
  //       propertiesLeasingPromise
  //         .then(async propertiesLeasing => {
  //           console.log('DEBUG >> propertiesLeasing', propertiesLeasing);
  //           if (!propertiesLeasing || propertiesLeasing.length === 0) {
  //             setNoPropertyFound(true);
  //             return;
  //           }
  //           propertiesLeasing.sort((a, b) => {
  //             const dateA = new Date(a.dateAvailable[0]);
  //             const dateB = new Date(b.dateAvailable[0]);
  //             return dateB - dateA;
  //           });
  //           propertiesLeasing = await filterLeasingImagesBySize(propertiesLeasing);
  //           globalStore.allLeasingProperties.length === 0 && globalStore.setLeasingProperties(propertiesLeasing);
  //           const fetchedProperty = propertiesLeasing.find(p => p.id === propertyId);
  //           if (fetchedProperty) {
  //             property = fetchedProperty;
  //           }
  //         })
  //         .catch(error => {
  //           console.log('Error fetching propertiesLease', error);
  //         });
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (property && property.images && property.images[0].img.length > 0) {
      property.images[0].img.forEach(img => {
        if (img.$ && img.$.url) {
          loadImageSize(imageProxyUrl(img.$.url));
        }
      });
    }
  }, [property]);


  const getPropertyAddress = useCallback(
    () => {
      return extractLeasingPropertyAddress(property);
    },
    [property],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const $ = window.$;

    // Get the form.
    var form = $('#contact-form');

    if(!property || !getPropertyAddress()) {
      return;
    }

    // Set up an event listener for the contact form.
    $(form).submit((e) => {
      // Stop the browser from submitting the form.
      e.preventDefault();

      setIsSubmitting(true);
      setShowAlert(false);

      // Serialize the form data.
      const formData = 'address=' + getPropertyAddress() + '&' + $(form).serialize();
      sendEmail(formData, `Enquire About Renting a Property`)
        .then(response => {
          setAlertMessage('Email sent successfully');
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 5000);
          console.log('Email sent successfully:', response.data);
        })
        .catch(error => {
          setAlertMessage('Error sending email. Please call us instead.');
          setShowAlert(true)
          console.error('Error sending email:', error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });

      console.log('DEBUG >> formData', formData);
    })
  }, [property]);

  const formatInspectionDate = (dateString) => {
    const [datePart, startTime, to, endTime] = dateString.split(' ');
    const [day, month, year] = datePart.split('-');

    const date = new Date(`${year}-${month}-${day}`);
    
    return {
      day: date.toLocaleString('en-US', { weekday: 'short' }),
      date: date.getDate(),
      month: date.toLocaleString('en-US', { month: 'short' }),
      time: `${startTime} to ${endTime}`
    };
  };

  const getUpcomingInspections = () => {
    if (property && property.inspectionTimes && property.inspectionTimes[0]) {
      console.log('getUpcomingInspections >> property.inspectionTimes', property.inspectionTimes);
      return property.inspectionTimes[0].inspection.filter(inspection => {
        const formattedDate = formatInspectionDate(inspection);
        return formattedDate.date >= new Date().getDate();
      });
    }
    return [];
  };

  const InspectionDetails = () => (
    <div className="ltn__inspection-widget">
      <h4 className="title-2">Inspection details</h4>
      <div className="inspection-cards">
        {getUpcomingInspections().map((inspection, index) => {
          const formattedDate = formatInspectionDate(inspection);
          console.log('InspectionDetails >> formattedDate', formattedDate);
          return (
            <div key={index} className="inspection-card">
              <div className="inspection-card__date">
                <span className="inspection-card__day">{formattedDate.day}</span>
                <span className="inspection-card__date-number">{formattedDate.date}</span>
                <span className="inspection-card__month">{formattedDate.month}</span>
              </div>
              <div className="inspection-card__time">
                <span>{formattedDate.time}</span>
              </div>
            </div>
          );
        })}
      </div>
      
      <div className="inspection-action-buttons" style={{
        display: 'flex',
        gap: '15px',
        marginBottom: '30px',
        maxWidth: '100%',
        justifyContent: 'space-between',
        padding: '0 15px'
      }}>
    {
      property && property.uniqueID && property.uniqueID[0] &&
      <>
      <a 
        href={`https://book.inspectrealestate.com.au/Register?agentid=PM-HoyeePG&uniqueID=${property.uniqueID[0]}&imgURL=${property.images[0]?.img[0] ? property.images[0].img[0]['$'].url : 'https://www.hoyee.com.au/assets/img/product-3/1.jpg'}&Type=rental`}
        target="_blank"
        rel="noopener noreferrer"
        className="btn theme-btn-1 btn-effect-1"
        style={{
          flex: '0 1 160px',
          textAlign: 'center',
          padding: '12px 15px',
          fontSize: '13px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          whiteSpace: 'nowrap'
        }}
      >
        Book Inspection
      </a>
      
      <a
        href={`https://2apply.com.au/Form?agentid=PM-HoyeePG&uniqueID=${property.uniqueID[0]}`}
        target="_blank"
        rel="noopener noreferrer" 
        className="btn theme-btn-1 btn-effect-1"
        style={{
          flex: '0 1 160px',
          textAlign: 'center',
          padding: '12px 15px',
          fontSize: '13px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          whiteSpace: 'nowrap'
        }}
      >
        Apply Now
      </a>
      </>
    }
    </div>
    </div>
  );

  return (
    <div className="ltn__shop-details-area pb-10" style={{ paddingTop: `${props.paddingTop}` }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="ltn__shop-details-inner mb-60">
              <div className="ltn__blog-meta">
                <ul>
                  {
                    getCategory() !== 'Sold' &&
                    <li className="ltn__blog-category">
                      <Link to="#">Featured</Link>
                    </li>
                  }
                  <li className="ltn__blog-category">
                    <Link className="bg-orange" to="#">{getCategory()}</Link>
                  </li>
                </ul>
              </div>

              <h1>{property && property.displayAddress}</h1>
              <label>
                <span className="ltn__secondary-color">
                  <i className="flaticon-pin" style={{verticalAlign: 'middle'}}/>
                </span>
                {fullAddress}
              </label>

              {/* Inspection Details for mobile */}
              <div className="d-md-none mobile-inspection-widget">
                <InspectionDetails />
              </div>

              {
                property && property.description && property.description[0] &&
                <>
                  <h4 className="title-2" id="description">Description</h4>
                  <div dangerouslySetInnerHTML={createMarkup(property.description[0])}/>
                </>
              }
              <h4 className="title-2">Property Features</h4>
              <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                {
                  property && property.landArea && property.landArea.value > 0 &&
                  <ul>
                    <li><label>Building size: </label> <span>{property.landArea.value} {property.landArea.units}</span>
                    </li>
                  </ul>
                }
                {
                  property && property.floorArea && property.floorArea.value > 0 &&
                  <ul>
                    <li><label>Building size: </label>
                      <span>{property.floorArea.value} {property.floorArea.units}</span></li>
                  </ul>
                }

                <ul>
                  <li style={{ display: 'flex', alignItems: 'center', lineHeight: '1.6', marginRight: '15px' }}>
                    <i className="flaticon-bed" style={{ marginRight: '8px', fontSize: '1.6em', display: 'flex' }}></i>
                    <label>Rooms:</label>
                    <span>{property.features?.[0]?.bedrooms?.[0] || ''}</span>
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center', lineHeight: '1.6', marginRight: '15px' }}>
                    <i className="flaticon-clean"
                       style={{ marginRight: '8px', fontSize: '1.6em', display: 'flex' }}></i>
                    <label>Baths:</label>
                    <span>{property.features?.[0]?.bathrooms?.[0] || ''}</span>
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center', lineHeight: '1.6', marginRight: '15px' }}>
                    <i className="flaticon-car" style={{ marginRight: '8px', fontSize: '1.6em', display: 'flex' }}></i>
                    <label>Carpark:</label>
                    <span>{extractLeasingPropertyCarSpaces(property) || ''}</span>
                  </li>
                </ul>
              </div>
              {
                property && property.images && property.images[0].img.length > 0 &&
                <>
                  <h4 className="title-2" id="photos">Gallery</h4>
                  <div className="ltn__property-details-gallery mb-30">
                    <div className="row">
                        <Gallery>
                          {
                            property.images[0].img.map((img) => {
                              if(img.$ && img.$.url) {
                                const imgUrl = imageProxyUrl(img.$.url);
                                const imageSize = imageSizes[imgUrl] || {};
                                return (
                                  <div className="col-md-6">
                                    <Item
                                      original={imgUrl}
                                      thumbnail={imgUrl}
                                      width={imageSize.width || 800}
                                      height={imageSize.height || 600}
                                    >
                                      {({ ref, open }) => (
                                        <img
                                          ref={ref}
                                          onClick={open}
                                          src={imageProxyUrl(img.$.url)}
                                          style={{ cursor: 'pointer', paddingBottom: '30px'}}
                                        />
                                      )}
                                    </Item>
                                  </div>
                                )
                              }
                            })
                          }
                        </Gallery>
                    </div>
                  </div>
                </>
              }

              {
                googleMapsSrc &&
                <>
                  <h4 className="title-2">Location</h4>
                  <div className="property-details-google-map mb-60">
                    <iframe
                      src={googleMapsSrc}
                      width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0}/>
                  </div>
                </>
              }
              {/*{*/}
              {/*  floorPlans.length > 0 &&*/}
              {/*  <>*/}
              {/*    <h4 className="title-2" id="floorplan">Floor Plans</h4>*/}
              {/*    /!* APARTMENTS PLAN AREA START *!/*/}
              {/*    <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">*/}
              {/*      <div*/}
              {/*        className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">*/}
              {/*        <div className="nav">*/}
              {/*          {floorPlans.map((plan, index) => (*/}
              {/*            <a key={index} data-bs-toggle="tab" href={`#floorplan_tab_${index}`} className={index === 0 ? "active show" : ""}>*/}
              {/*              {`Floor ${index + 1}`}*/}
              {/*            </a>*/}
              {/*          ))}*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="tab-content">*/}
              {/*        {floorPlans.map((plan, index) => (*/}
              {/*          <div key={index} className={`tab-pane fade ${index === 0 ? "active show" : ""}`} id={`floorplan_tab_${index}`}>*/}
              {/*            <div className="ltn__apartments-tab-content-inner">*/}
              {/*              <div className="row">*/}
              {/*                <div className="col-lg-12">*/}
              {/*                  <div className="apartments-plan-img">*/}
              {/*                    <img src={plan.url} alt={`Floor plan ${index + 1}`} />*/}
              {/*                  </div>*/}
              {/*                </div>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*        ))}*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </>*/}
              {/*}*/}

              {/* APARTMENTS PLAN AREA END */}
              {/*<h4 className="title-2">Property Video</h4>*/}
              {/*<div className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg-image mb-60"*/}
              {/*     data-bs-bg={publicUrl + 'assets/img/others/5.jpg'}>*/}
              {/*  <a className="ltn__video-icon-2 ltn__video-icon-2-border---"*/}
              {/*     href="https://www.youtube.com/embed/_Q09ditbJnk?autoplay=1&showinfo=0"*/}
              {/*     data-rel="lightcase:myCollection">*/}
              {/*    <i className="fa fa-play"/>*/}
              {/*  </a>*/}
              {/*</div>*/}

              {/*<h4 className="title-2">You Might Also Like…</h4>*/}
              {/*<div className="row">*/}
              {/*  /!* ltn__product-item *!/*/}
              {/*  <div className="col-xl-6 col-sm-6 col-12 go-top">*/}
              {/*    <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">*/}
              {/*      <div className="product-img">*/}
              {/*        <Link to="/shop"><img src={publicUrl + "assets/img/product-3/1.jpg"} alt="#"/></Link>*/}
              {/*        <div className="real-estate-agent">*/}
              {/*          <div className="agent-img">*/}
              {/*            <Link to="/team-details"><img src={publicUrl + "assets/img/blog/author.jpg"} alt="#"/></Link>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="product-info">*/}
              {/*        <div className="product-badge">*/}
              {/*          <ul>*/}
              {/*            <li className="sale-badg">For Rent</li>*/}
              {/*          </ul>*/}
              {/*        </div>*/}
              {/*        <h2 className="product-title"><Link to="/shop">New Apartment Nice View</Link></h2>*/}
              {/*        <div className="product-img-location">*/}
              {/*          <ul>*/}
              {/*            <li>*/}
              {/*              <Link to="/shop"><i className="flaticon-pin"/> 22 Ivenhoe, VIC, 3079</Link>*/}
              {/*            </li>*/}
              {/*          </ul>*/}
              {/*        </div>*/}
              {/*        <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">*/}
              {/*          <li><span>3 </span>*/}
              {/*            Bedrooms*/}
              {/*          </li>*/}
              {/*          <li><span>2 </span>*/}
              {/*            Bathrooms*/}
              {/*          </li>*/}
              {/*          <li><span>100 </span>*/}
              {/*            Square meters*/}
              {/*          </li>*/}
              {/*        </ul>*/}
              {/*        <div className="product-hover-action">*/}
              {/*          <ul>*/}
              {/*            <li>*/}
              {/*              <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">*/}
              {/*                <i className="flaticon-expand"/>*/}
              {/*              </a>*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*              <a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">*/}
              {/*                <i className="flaticon-heart-1"/></a>*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*              <Link to="/shop" title="Compare">*/}
              {/*                <i className="flaticon-add"/>*/}
              {/*              </Link>*/}
              {/*            </li>*/}
              {/*          </ul>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="product-info-bottom">*/}
              {/*        <div className="product-price">*/}
              {/*          <span>$349,00<label>/Month</label></span>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  /!* ltn__product-item *!/*/}
              {/*  <div className="col-xl-6 col-sm-6 col-12 go-top">*/}
              {/*    <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">*/}
              {/*      <div className="product-img">*/}
              {/*        <Link to="/shop"><img src={publicUrl + "assets/img/product-3/2.jpg"} alt="#"/></Link>*/}
              {/*        <div className="real-estate-agent">*/}
              {/*          <div className="agent-img">*/}
              {/*            <Link to="/team-details"><img src={publicUrl + "assets/img/blog/author.jpg"} alt="#"/></Link>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="product-info">*/}
              {/*        <div className="product-badge">*/}
              {/*          <ul>*/}
              {/*            <li className="sale-badg">For Sale</li>*/}
              {/*          </ul>*/}
              {/*        </div>*/}
              {/*        <h2 className="product-title"><Link to="/shop">New Apartment Nice View</Link></h2>*/}
              {/*        <div className="product-img-location">*/}
              {/*          <ul>*/}
              {/*            <li>*/}
              {/*              <Link to="/shop"><i className="flaticon-pin"/> 22 Ivenhoe, VIC, 3079</Link>*/}
              {/*            </li>*/}
              {/*          </ul>*/}
              {/*        </div>*/}
              {/*        <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">*/}
              {/*          <li><span>3 </span>*/}
              {/*            Bedrooms*/}
              {/*          </li>*/}
              {/*          <li><span>2 </span>*/}
              {/*            Bathrooms*/}
              {/*          </li>*/}
              {/*          <li><span>100 </span>*/}
              {/*            Square meters*/}
              {/*          </li>*/}
              {/*        </ul>*/}
              {/*        <div className="product-hover-action">*/}
              {/*          <ul>*/}
              {/*            <li>*/}
              {/*              <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">*/}
              {/*                <i className="flaticon-expand"/>*/}
              {/*              </a>*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*              <a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">*/}
              {/*                <i className="flaticon-heart-1"/></a>*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*              <a href="portfolio-details.html" title="Compare">*/}
              {/*                <i className="flaticon-add"/>*/}
              {/*              </a>*/}
              {/*            </li>*/}
              {/*          </ul>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="product-info-bottom">*/}
              {/*        <div className="product-price">*/}
              {/*          <span>$349,00<label>/Month</label></span>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
              {/* Author Widget */}
              <div className="widget ltn__author-widget">
                <div className="ltn__author-widget-inner text-center" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <img src={'/assets/img/H.PM.jpg'} alt="Image"/>
                  <h5>{agentName}</h5>
                  {/*<small>Traveller/Photographer</small>*/}
                  <div className="product-ratting">
                    <ul>
                      <li><a href="#"><i className="fas fa-star"/></a></li>
                      <li><a href="#"><i className="fas fa-star"/></a></li>
                      <li><a href="#"><i className="fas fa-star"/></a></li>
                      <li><a href="#"><i className="fas fa-star"/></a></li>
                      <li><a href="#"><i className="fas fa-star"/></a></li>
                    </ul>
                  </div>
                  <div className="contact-info" style={{with: '60%', display: 'flex', flexDirection: 'column', alignItems: 'self-start'}}>
                    <p style={{marginBottom: '5px'}}>
                      <i className="fab icon-mail" style={{paddingRight: '1em'}}/>
                      <a href={`mailto:${agentEmail}`}>{agentEmail}</a>
                    </p>
                    <p style={{marginBottom: '5px'}}>
                      <i className="fab icon-phone-call" style={{paddingRight: '1em'}}/>
                      <a href={`tel:${agentPhone}`}>{agentPhone}</a>
                    </p>
                  </div>
                  {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis distinctio, odio, eligendi*/}
                  {/*  suscipit reprehenderit atque.</p>*/}
                  {/*<div className="ltn__social-media">*/}
                  {/*  <ul>*/}
                  {/*    <li><a href="#" title="Facebook"><i className="fab fa-facebook-f"/></a></li>*/}
                  {/*    <li><a href="#" title="Twitter"><i className="fab fa-twitter"/></a></li>*/}
                  {/*    <li><a href="#" title="Linkedin"><i className="fab fa-linkedin"/></a></li>*/}
                  {/*    <li><a href="#" title="Youtube"><i className="fab fa-youtube"/></a></li>*/}
                  {/*  </ul>*/}
                  {/*</div>*/}
                </div>
              </div>

              {/* Inspection Details for desktop */}
                <div className="d-none d-md-block">
                <InspectionDetails />
              </div>

              {/* Form Widget */}
              <div className="widget ltn__form-widget">
                <h4 className="ltn__widget-title ltn__widget-title-border-2">Enquiry</h4>
                <form id='contact-form'>
                  <input type="text" name="name" placeholder="Your Name"/>
                  <input type="text" name="email" placeholder="Your e-Mail*" required/>
                  <input type="text" name="phone" placeholder="Your phone number*" required/>
                  <textarea name="message" placeholder="Write Message..." defaultValue={''}/>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      type="submit"
                      className="btn theme-btn-1"
                      disabled={isSubmitting}>
                      Send
                    </button>
                  </div>
                </form>
                {showAlert && <AlertMessage message={alertMessage} type={alertMessage.startsWith('Error') ? 'error' : 'success'} />}
              </div>
              {/* Banner Widget */}
              <div className="widget ltn__banner-widget d-none go-top">
                <Link to="/shop"><img src={publicUrl + 'assets/img/banner/2.jpg'} alt="#"/></Link>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopDetailsLease
